import React from 'react';
import Layout from '../components/layout/Layout';
import Button from '../components/Button';
import { Section, SectionTitle } from '../components/Section';
import { Card, CardContent } from '../components/Card';
import { Helmet } from 'react-helmet';

// need helper function to styling properly based on bullet points
const styleJobBlurb = (description) => {
  let formattedDescription = '';
  if (description) {
    const descriptionArr = description.split('**'); // split on ** symbol
    descriptionArr.forEach((section) => {
      const sectionArr = section.split(' - '); // split on individual list items
      sectionArr.forEach((line) => {
        formattedDescription += line;
      });
    });
  }
  return formattedDescription;
};

const Job = ({ pageContext }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>LightFeather - {pageContext.jobTitle}</title>
      </Helmet>
      <Section>
        <SectionTitle headingLevel='h1' className='text-blue'>
          {pageContext.jobTitle}
        </SectionTitle>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1'>
            <Card className='shadow-none'>
              <CardContent className='prose lg:prose-lg max-w-6xl'>
                <ul>
                  {styleJobBlurb(pageContext.postingBlurb)
                    .split('\n')
                    .map((str, key) => {
                      if (str === '') {
                        return <br />;
                      } else if (str.charAt(0) === '-') {
                        return <li key={key}>{str.substring(1)}</li>;
                      } else {
                        return <div key={key}>{str}</div>;
                      }
                    })}
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
        <SectionTitle headingLevel='h3' className='text-blue'>
          Required Qualifications:
        </SectionTitle>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1'>
            <Card className='shadow-none'>
              <CardContent className='prose lg:prose-lg max-w-6xl'>
                <ul>
                  {pageContext.qualifications === undefined
                    ? null
                    : pageContext.qualifications
                        .split('- ')
                        .filter((i) => i.length > 0)
                        .map((qualification, index) => <li key={index}>{qualification}</li>)}
                </ul>
              </CardContent>
              <CardContent>
                <a href={pageContext.applicationLink || 'mailto:jobs@lightfeather.io'}>
                  <Button className='mt-10'>Apply Now</Button>
                </a>
              </CardContent>
            </Card>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Job;
